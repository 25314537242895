
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ListaPortfolioComercial from "../components/lista-portfolio-comercial"

export const pageQuery = graphql`
  query ListaPortfolioComercialQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
			frontmatter {
        title_seo
        description_seo
      }
    }
  }
`

const PortfolioComercialPage = ({data}) => {
	const { markdownRemark } = data
  const { frontmatter } = markdownRemark

	return (
		<Layout>
			<SEO 
        title={frontmatter.title_seo}
        description={frontmatter.description_seo} 
      />
			<section className="portfolio top-espaco">
				<div className="container">
					<h3>PORTFÓLIO COMERCIAL</h3>
					<ListaPortfolioComercial />
				</div>
			</section>
		</Layout>
	)
}

export default PortfolioComercialPage