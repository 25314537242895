import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PortfolioCard from "./portfolio-card"

const ComercialMaker = ({ data }) => (
    <>
      {data}
    </>
)

export default function ListaPortfolioComercial() {
  return (
    <StaticQuery 
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___ordem] }
            filter: { frontmatter: { template: { eq: "portfolio-comercial" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  slug
                  title
                  ordem
                  description
                  featuredImage {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
              }
            }
          }
        }`
      }

      render={ data => {
          const portfolios = data.allMarkdownRemark.edges
            .filter(edge => !!edge.node.frontmatter.ordem)
            .map(edge => <PortfolioCard key={edge.node.id} data={edge.node} />)
          return <ComercialMaker data={portfolios} />
        } 
      }
    />
  )
}